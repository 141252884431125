import classNames from 'classnames';

import { Typography } from 'components/typography';

import { Icon, Icons } from '../internalComponents/icon';
import styles from './styles.module.scss';

export type PillProps = {
  icon?: keyof typeof Icons;
  children: string;
  variant?: 'default' | 'inverted';
};

export function Pill({ icon, children, variant = 'default' }: PillProps) {
  return (
    <div className={classNames(styles.pill, styles[variant])}>
      {icon && (
        <Icon
          className={classNames(styles.icon, styles[variant])}
          name={icon}
        />
      )}
      <Typography
        tag="p"
        variant="label-small"
        color={variant === 'inverted' ? 'white' : 'secondary'}
        className={styles.label}
      >
        {children}
      </Typography>
    </div>
  );
}

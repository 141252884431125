import {
  type BlocksContent,
  BlocksRenderer,
} from '@strapi/blocks-react-renderer';

import { Typography } from 'components/typography';

import { TempTextLink } from './temp-text-link';

export function BlocksRendererClient({ content }: { content: BlocksContent }) {
  return (
    <BlocksRenderer
      content={content}
      blocks={{
        paragraph: ({ children }) => (
          <Typography variant="lead" tag="p">
            {children}
          </Typography>
        ),
        link: ({ url, children }) => (
          <TempTextLink url={url}>{children}</TempTextLink>
        ),
      }}
    />
  );
}

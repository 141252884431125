'use client';
import NextHead from 'next/head';

import { Seo } from '@/types/misc';

export const Head = ({ seo, title }: { seo?: Seo; title: string }) => {
  const facebookMeta = seo?.metaSocial?.find(
    (item) => item.socialNetwork === 'Facebook'
  );

  const twitterMeta = seo?.metaSocial?.find(
    (item) => item.socialNetwork === 'Twitter'
  );

  if (
    seo &&
    seo.metaViewport &&
    seo.metaViewport.length > 0 &&
    !seo.metaViewport.includes('initial-scale=1')
  ) {
    seo.metaViewport = seo.metaViewport + ', initial-scale=1';
  }

  return (
    <NextHead>
      <title>{`${seo?.metaTitle ?? title} | [code]capi`}</title>
      <link
        rel="sitemap"
        type="application/xml"
        title="Sitemap"
        href="https://www.codecapi.nl/sitemap.xml"
      />
      {seo && (
        <>
          <meta name="description" content={seo.metaDescription} />
          {seo.keywords && <meta name="keywords" content={seo.keywords} />}
          {seo.metaViewport && (
            <meta name="viewport" content={seo.metaViewport} />
          )}
          {!seo.metaViewport && (
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
          )}
          {seo.canonicalURL && <link rel="canonical" href={seo.canonicalURL} />}

          {facebookMeta && (
            <>
              <meta property="og:type" content="website" />
              <meta
                property="og:title"
                content={facebookMeta.title ?? seo.metaTitle ?? title}
              />
              {facebookMeta.description && (
                <meta
                  property="og:description"
                  content={facebookMeta.description}
                />
              )}
              <meta property="og:image" content={facebookMeta.image} />
            </>
          )}

          {twitterMeta && (
            <>
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={twitterMeta.title} />
              <meta
                name="twitter:description"
                content={twitterMeta.description}
              />
              <meta name="twitter:image" content={twitterMeta.image} />
            </>
          )}

          {seo.structuredData && (
            <script type="application/ld+json">{`${JSON.stringify(
              seo.structuredData
            )}`}</script>
          )}
        </>
      )}
      {!seo && (
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      )}
    </NextHead>
  );
};

'use client';
import { useState } from 'react';
import { Skeleton } from '@/components/skeleton/skeleton';
import Image from 'next/image';
import classNames from 'classnames';
import { aspectRatio } from '../helpers/calculateAspectRatio';

export interface MultiContentRowProps {
  content: { name: string; url: string; width?: number; height?: number }[];
}

export function MultiContentRow({ content }: MultiContentRowProps) {
  const isRow = content.length > 1;
  const isVideo = content[0].url.endsWith('.mp4');
  const [loading, setLoading] = useState(true);

  const dismissLoading = () => {
    setLoading(false);
  };

  const Loader = () =>
    content.map(({ width = 0, height = 0, name }, index) => (
      <Skeleton
        key={name}
        loading={loading}
        style={{ aspectRatio: aspectRatio(width, height) }}
        className={classNames(
          { ['order-2 lg:order-none']: index % 2 === 0 },
          'w-[48%] lg:w-[24%]'
        )}
      />
    ));

  return (
    <>
      {isRow ? (
        <>
          {loading ? (
            <div className="flex items-start lg:items-center flex-wrap lg:flex-nowrap gap-4 lg:gap-10 mx-4 lg:mx-10">
              <Loader />
            </div>
          ) : null}
          <div
            className={classNames(
              { ['invisible absolute']: loading },
              'flex items-start lg:items-center flex-wrap lg:flex-nowrap gap-4 lg:gap-10 mx-4 lg:mx-10'
            )}
          >
            {content.map((image, index) => {
              return (
                <div
                  key={image.name}
                  className={classNames(
                    {
                      ['order-2 lg:order-none']: index % 2 === 0,
                      ['invisible']: loading,
                    },
                    'w-[48%] lg:w-[24%]'
                  )}
                >
                  <Image
                    fill
                    alt={image.name}
                    sizes="(max-width: 639px) 100vw,
              (max-width: 1279px) 50vw,
              33vw"
                    className="object-contain !h-fit !relative"
                    src={image.url}
                    onLoad={dismissLoading}
                  />
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div className="max-w-[105rem] m-auto">
          {isVideo ? (
            <video muted autoPlay className="max-w-[105rem] m-auto" controls>
              <source src={content[0].url} type="video/mp4" />
            </video>
          ) : (
            <Skeleton loading={loading}>
              <div className="">
                <Image
                  fill
                  alt={content[0].name}
                  sizes="(max-width: 639px) 100vw,
              (max-width: 1279px) 50vw,
              33vw"
                  className="object-contain !h-fit !relative"
                  src={content[0].url}
                  onLoad={dismissLoading}
                />
              </div>
            </Skeleton>
          )}
        </div>
      )}
    </>
  );
}

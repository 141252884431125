import { ReactNode } from 'react';

import styles from './styles.module.scss';

export function TempTextLink({
  children,
  url,
}: {
  children: ReactNode;
  url: string;
}) {
  return (
    <a className={styles.textLink} href={url}>
      {children}
    </a>
  );
}

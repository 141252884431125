import { AuthorThumbnailProps } from '@/types/authorThumbnailProps';

export const mapAuthor = (
  author: AuthorThumbnailProps,
  publishedAt: string
) => {
  if (!author.data?.attributes?.thumbnail?.data?.attributes?.url)
    return {
      title: `Door [code]capi`,
      subTitle: new Date(publishedAt).toLocaleDateString('nl-NL', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }),
    };
  return {
    title: `Door ${author.data.attributes.firstName}`,
    subTitle: new Date(publishedAt).toLocaleDateString('nl-NL', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    }),
    author: {
      name: author.data.attributes.firstName,
      image: author.data.attributes.thumbnail.data.attributes.url,
    },
  };
};

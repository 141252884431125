import { Typography } from 'components/typography';

import styles from './styles.module.scss';
import { NamedImageType } from '@/types/misc';

export type AuthorProps = {
  title: string;
  subTitle: string;
  author?: NamedImageType;
};

export function Author({ title, subTitle, author }: AuthorProps) {
  return (
    <div className={styles.author}>
      {author?.image && (
        <img src={author.image} alt={author.name} className={styles.image} />
      )}
      <div className={styles.content}>
        <Typography tag="p" variant="label-small">
          {title}
        </Typography>
        <Typography tag="p" variant="label-small" color="muted">
          {subTitle}
        </Typography>
      </div>
    </div>
  );
}

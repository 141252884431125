'use client';
import classNames from 'classnames';
import { Author, AuthorProps } from 'components/author';
import { BlocksRendererClient } from 'components/blocks-renderer-client';
import { LayoutContainer } from 'components/layout-container';
import { LinkButton } from 'components/link-button';
import { Pill, PillProps } from 'components/pill';
import { Typography } from 'components/typography';
import { StrapiButton } from '@/types/misc';
import { Button } from '../commonComponents/button/button';
import { MultiContentRow } from './multi-content-row';
import styles from './styles.module.scss';
import { BlocksContent } from '@strapi/blocks-react-renderer';
import { useMemo } from 'react';
import { RootNode } from '@strapi/blocks-react-renderer/dist/BlocksRenderer';
import { scrollTo } from '@/helpers/scrollTo';
import { mapAuthor } from '@/components/hero/mapAuthor';
import { AuthorThumbnailProps } from '@/types/authorThumbnailProps';
import type { MultiContentRowProps } from './multi-content-row';

export type HeroVariant = 'default' | 'compact';

export type HeroProps = {
  pageTitle: string;
  title: string;
  lead: string | BlocksContent;
  pills?: PillProps[];
  author?: AuthorThumbnailProps;
  publishedAt?: string;
  buttons?: [StrapiButton?, StrapiButton?];
  multiContent?: MultiContentRowProps['content'];
  variant?: HeroVariant;
  age?: string;
};

export function Hero({
  pageTitle,
  title,
  lead,
  pills,
  author,
  publishedAt,
  buttons,
  multiContent,
  variant = 'default',
  age,
}: HeroProps) {
  const hasMultiContentRow = multiContent && multiContent.length > 0;

  const mappedAuthor: AuthorProps | undefined =
    author && publishedAt ? mapAuthor(author, publishedAt) : undefined;

  const primaryButton = buttons &&
    (buttons[0]?.href ||
      buttons[0]?.pageLink?.slug ||
      buttons[0]?.scrollTo) && {
      ...(buttons[0]?.href && { href: buttons[0]?.href }),
      ...(buttons[0]?.pageLink && { href: buttons[0]?.pageLink?.slug }),
      ...(buttons[0]?.scrollTo && {
        onClick: () =>
          scrollTo((buttons as StrapiButton[])[0].scrollTo as string, 0),
      }),
      ...(buttons[0]?.surface && { surface: buttons[0].surface }),
      children: buttons[0]?.copy || 'Meer info',
    };

  useMemo(() => {
    if (!lead || !age) return;
    if (typeof lead === 'string') {
      return lead.replace(/\[averageAge]/g, age);
    }
    if (Array.isArray(lead)) {
      return lead.map((blocks: RootNode) => {
        if (!blocks.children) return blocks;
        (blocks.children as any) = blocks.children.map((child) => {
          if (child.type === 'text' && child.text.includes('[averageAge]')) {
            child.text = child.text.replace('[averageAge]', age);
          }
          return child;
        });
        return blocks;
      });
    }
  }, [lead, age]);

  return (
    <div className={classNames(styles.hero, styles[variant])}>
      <LayoutContainer
        tag="section"
        className={classNames(
          styles.container,
          hasMultiContentRow && styles.hasMultiContentRow
        )}
      >
        <div className={styles.content}>
          <Typography tag="h4" variant="heading1-sub" color="primary">
            {pageTitle}
          </Typography>
          <Typography tag="h1" variant="heading1-display">
            {title}
          </Typography>
          {pills && pills.length > 0 && (
            <div className={styles.pills}>
              {pills.map((pill) => (
                <Pill key={pill.icon + pill.children} icon={pill.icon}>
                  {pill.children}
                </Pill>
              ))}
            </div>
          )}
          {mappedAuthor && <Author {...mappedAuthor} />}
          {lead &&
            (typeof lead === 'string' ? (
              <Typography variant="lead" tag="p">
                {lead}
              </Typography>
            ) : (
              <BlocksRendererClient content={lead} />
            ))}
          {buttons && (
            <div className={styles.buttons}>
              {primaryButton && (
                <Button style="primary" {...primaryButton}>
                  {primaryButton.children}
                </Button>
              )}
              {(buttons[1]?.href || buttons[1]?.pageLink?.slug) && (
                <LinkButton
                  href={buttons[1]?.href || buttons[1]?.pageLink?.slug}
                  className={styles.underlineButton}
                  label={buttons[1]?.copy || 'Meer info'}
                />
              )}
            </div>
          )}
        </div>
      </LayoutContainer>
      {hasMultiContentRow && <MultiContentRow content={multiContent} />}
    </div>
  );
}
